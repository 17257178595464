export default {
    'home':'首页',
    'about_us':'关于SigmaStar',
    'company_address':'联系我们',
    'contact_us':'联系我们',
    'market_and_solution':'场景及应用',
    'changjing':'场景',
    'cn':'中文',
    'en':'英文',
    'news_center':'新闻中心',
    'developer_Community':'开发者社区',
    'hr_resource':'人力资源',
    'training':'人才培育',
    'join_us':'加入我们',
    'honor':'荣誉资质',
    'investor':'投资者关系',
    'investorService':'投资者服务',
    'report':'公司公告',
    'stock_code':'股票代码',
    'email':'邮箱',
    'kefu':'客服',
    'phone':'电话',
    'legalNotice':'法律声明',
    'copyright':'版权商标',
    'filing_number':'备案号：闽ICP备19019072号',
    'copyright_belongs':'版权所有：星宸科技股份有限公司',
    'market':'市场',
    'solution':'行业',
    'pre':'上一篇',
    'next':'下一篇',
    'rel_products':'相关产品',
    'feature':'特色亮点',
    'xxzx':'选型咨询',
     'go_to_choose_sim' :'进入选型咨询通道' ,
     'address':'地址',
     'express':'传真',

     

    


}